import { ORG_CLASSIFICATION } from "../../config";

export function checkReviewerPermissions(permissions) {
  for (var i = 0; i < permissions.length; i++) {
    var perm = permissions[i];
    var reviewModel = perm.split(":");
    if (reviewModel.length === 2 && reviewModel[1] === "review") {
      return true;
    }
  }
  return false;
}

export function checkClassTestPermissions(permissions) {
  for (var i = 0; i < permissions.length; i++) {
    var perm = permissions[i];
    var betaModel = perm.split(":");
    if (betaModel.length === 2 && betaModel[1] === "beta") {
      return true;
    }
  }
  return false;
}

export function getOrgClassification(currentInstitute) {
  if (
    (currentInstitute.organization &&
      currentInstitute.organization.classification &&
      (currentInstitute.organization.classification.toLowerCase() ===
        "higher ed" ||
        currentInstitute.organization.classification.toLowerCase() ===
          "higher education")) ||
    (currentInstitute.classification &&
      (currentInstitute.classification.toLowerCase() === "higher ed" ||
        currentInstitute.classification.toLowerCase() === "higher education"))
  ) {
    return ORG_CLASSIFICATION.HIGHER_ED;
  } else {
    return ORG_CLASSIFICATION.SCHOOL;
  }
}

const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");
  
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  
  return "";
};


export const getOneTrustCookieConsent = () => {
  const onetrustCookie = getCookie("OptanonConsent");
  if (onetrustCookie) {
    const optanonConsent = onetrustCookie.split("&");
    for (let i = 0; i < optanonConsent.length; i++) {
      if (optanonConsent[i].includes("groups=")) {
        return optanonConsent[i].replace("groups=", "");
      }
    }
  }
  else if (window.piSession?.piCookiePreferences()) {
    return window.piSession.piCookiePreferences();
  }
  else return "";
};

export const getOneTrustCookieConsentHosts = () => {
  const onetrustCookie = getCookie("OptanonConsent");
  if (onetrustCookie) {
    const optanonConsent = onetrustCookie.split("&");
    for (let i = 0; i < optanonConsent.length; i++) {
      if (optanonConsent[i].includes("hosts=")) {
        return optanonConsent[i].replace("hosts=", "");
      }
    }
  }
  else if (window.piSession?.piCookiePreferences()) {
    return window.piSession.piCookiePreferences();
  }  
  else return "";
  
};

export const formatOneTrustConsent = (consent) => {
  const consentArray = consent.split(",");
  const consentJson = {};

  for (const item of consentArray) {
    const [key, value] = item.split(":");
    consentJson[key] = parseInt(value);
  }

  return consentJson;
};

