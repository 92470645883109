import React, { useState, useEffect } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Home } from "./components/pages/home/Home";
import { Srp } from "./components/pages/srp/Srp";
import { Pdp } from "./components/pages/pdp/Pdp";
import { GetStart } from "./components/pages/getStart/GetStart";
import { Footer } from "@console/console-footer";
import { NotFound } from "./components/pages/notFound/NotFound";
import { RedeemCode } from "./components/pages/redeemCode/RedeemCode";
import { useSelector, useDispatch } from "react-redux";
import { updateUserConsent } from "./components/widgets/exHeader/userSlice";
import {
  getOneTrustCookieConsent,
  formatOneTrustConsent,
} from "./components/util/userUtil";
import ReactGA from "react-ga4";

function App() {
  const [consentGA, setConsentGA] = useState(false);

  let config = window.__env.configs["appConfig"];

  const dispatch = useDispatch();
  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );

  function getConsentFromCookies() {
    const strConsent = getOneTrustCookieConsent();
    if (strConsent && strConsent.length > 0) {
      return formatOneTrustConsent(strConsent);
    }
  }

  function initializeGA() {
    return new Promise((resolve) => {
      if (getConsentFromCookies()?.C0002) {
        window[
          "ga-disable-" + window.__env.configs["appConfig"].GA_CONTAINER_ID
        ] = false;
        ReactGA.initialize(config.GA_CONTAINER_ID);
        resolve();
      } else {
        window[
          "ga-disable-" + window.__env.configs["appConfig"].GA_CONTAINER_ID
        ] = true;
        resolve();
      }
    });
  }

  useEffect(() => {
    if (!window.__env.configs["appConfig"].GA_DISABLE) {
      initializeGA().then(() => {
        dispatch(updateUserConsent());
      });
    }
  }, [consentGA]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.OneTrust?.OnConsentChanged(() => {
        setConsentGA((previous) => !previous);
      });
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/search">
          <Srp />
        </Route>
        <Route path="/products/:id/:slug">
          <Pdp />
        </Route>
        <Route path="/products/:id">
          <Pdp />
        </Route>
        <Route exact path="/getstarted">
          <GetStart />
        </Route>
        <Route exact path="/redeemcode">
          <RedeemCode />
        </Route>
        <Route component={NotFound} />
      </Switch>

      {!isMobileSearchView && (
        <Footer
          oneTrustEnabled={true}
          mainLinks={[
            {
              key: "terms_of_use",
              href: "https://login.pearson.com/v1/piapi/policies/static/html/PearsonEULA.html",
            },
            {
              key: "accessibility",
              href: "https://www.pearson.com/us/accessibility.html",
            },
            {
              key: "permissions",
              href: "https://www.pearson.com/us/contact-us/permissions.html",
            },
            {
              key: "support",
              href: "https://support.pearson.com/getsupport/s/",
            },
          ]}
        />
      )}
    </Router>
  );
}

export default App;
