import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchProduct,
  fetchProductOffers,
  fetchAvailableToBuyOffers,
  fetchProductDisciplineFeature,
  fetchProductToc,
  fetchProductModule,
} from "./productApi";

const initialState = {
  productData: null,
  productOffers: null,
  productAvailableToBuyOffers: null,
  productDisciplineFeature: null,
  productMobileFeature: null,
  productToc: null,
  productModule: null,
  relatedProductData: null,
  productTabs: null,
  showDDA: false,
  isDisplayPdp: true,
  isProductAvailable: true,
  isProductInternalError: false,
  isOfferInternalError: false,
  isAvailableToBuyOffersInternalError: false,
  isProductDisciplineInternalError: false,
  isActiveSeatLicenseAvaliable: false,
  activeMobileProductTab: null,
};

export const productAsync = createAsyncThunk(
  "product/fetchProduct",
  async (args) => {
    const response = await fetchProduct(
      args.productId,
      args.isRelated,
      args.lmsIntegrationToken
    );
    response.data.isRelated = args.isRelated;
    return response.data;
  }
);

export const productOffersAsync = createAsyncThunk(
  "product/fetchProductOffers",
  async (args) => {
    let org = "";
    if (args.orgId) {
      org = `${args.orgId}|${args.location.toUpperCase()}`;
    }
    const response = await fetchProductOffers(
      args.productId,
      org,
      args.lmsIntegrationToken
    );
    return response.data;
  }
);

export const productDisciplineFeatureAsync = createAsyncThunk(
  "product/fetchProductDisciplineFeature",
  async (args) => {
    const response = await fetchProductDisciplineFeature(
      args.instrFeature,
      args.isLearningApp,
      args.pDiscipline,
      args.pId,
      args.pModel
    );
    return response.data;
  }
);

export const productTocAsync = createAsyncThunk(
  "product/fetchProductToc",
  async (args) => {
    const response = await fetchProductToc(args.productId);
    return response.data;
  }
);

export const productModuleAsync = createAsyncThunk(
  "product/fetchProductModule",
  async (args) => {
    const response = await fetchProductModule(args.productId);
    return response.data;
  }
);

export const productAvailableToBuyOffersAsync = createAsyncThunk(
  "product/fetchAvailableToBuyOffers",
  async (args) => {
    const response = await fetchAvailableToBuyOffers(
      args.productId
    );
    return response.data;
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    updateProductTabs: (state, action) => {
      state.productTabs = { ...state.productTabs, ...action.payload };
    },
    updateShowDDA: (state, action) => {
      state.showDDA = action.payload;
    },
    resetProductData: (state, action) => {
      state.productData = null;
      state.productOffers = null;
      state.productAvailableToBuyOffers = null;
      state.productDisciplineFeature = null;
      state.productToc = null;
      state.productModule = null;
      state.relatedProductData = null;
      state.productTabs = null;
      state.showDDA = false;
      state.isDisplayPdp = true;
      state.isProductAvailable = true;
      state.productMobileFeature = null;
      state.isActiveSeatLicenseAvaliable = false;
    },
    resetProductOfferData: (state, action) => {
      state.productOffers = null;
      state.showDDA = false;
      state.isActiveSeatLicenseAvaliable = false;
    },
    updateIsProductInternalError: (state, action) => {
      state.isProductInternalError = action.payload;
    },
    updateIsOfferInternalError: (state, action) => {
      state.isOfferInternalError = action.payload;
    },
    updateIsAvailableToBuyOffersInternalError: (state, action) => {
      state.isAvailableToBuyOffersInternalError = action.payload;
    },
    updateIsProductDisciplineInternalError: (state, action) => {
      state.isProductDisciplineInternalError = action.payload;
    },
    updateIsActiveSeatLicenseAvaliable: (state, action) => {
      state.isActiveSeatLicenseAvaliable = action.payload;
    },
    updateActiveMobileProductTab: (state, action) => {      
      state.activeMobileProductTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productAsync.fulfilled, (state, action) => {
        if (action.payload.isRelated) {
          state.relatedProductData = action.payload;
        } else {
          state.productData = action.payload;
          state.isDisplayPdp = true;
          state.isProductAvailable = true;
          state.isProductInternalError = false;
        }
      })
      .addCase(productAsync.rejected, (state, action) => {
        if (
          action &&
          action.meta &&
          action.meta.arg &&
          !action.meta.arg.isRelated &&
          action.error &&
          action.error.message.includes("404")
        ) {
          state.isProductAvailable = false;
        } else if (
          action &&
          action.meta &&
          action.meta.arg &&
          !action.meta.arg.isRelated &&
          action.error &&
          action.error.message.includes("401")
        ) {
          if (window.piSession) {
            window.piSession.login();
          }
        } else if (
          action &&
          action.meta &&
          action.meta.arg &&
          !action.meta.arg.isRelated &&
          action.error &&
          action.error.message.includes("403")
        ) {
          state.isDisplayPdp = false;
        } else if (
          action &&
          action.error &&
          action.error.message.includes("500")
        ) {
          state.isProductInternalError = true;
        }
      })
      .addCase(productOffersAsync.fulfilled, (state, action) => {
        state.productOffers = action.payload;
        state.isOfferInternalError = false;
      })
      .addCase(productOffersAsync.rejected, (state, action) => {
        if (action && action.error && action.error.message.includes("500")) {
          state.isOfferInternalError = true;
        }
      })
      .addCase(productDisciplineFeatureAsync.fulfilled, (state, action) => {
        let tempFeatures = action.payload;
        if (tempFeatures && tempFeatures.contents) {
          for (let index = 0; index < tempFeatures.contents.length; index++) {
            if (
              tempFeatures.contents[index].appCTAs &&
              tempFeatures.contents[index].appCTAs.length > 0
            ) {
              state.productMobileFeature = tempFeatures.contents[index];
              tempFeatures.contents.splice(index, 1);
              break;
            }
          }
        }
        state.productDisciplineFeature = tempFeatures;
        state.isProductDisciplineInternalError = false;
      })
      .addCase(productDisciplineFeatureAsync.rejected, (state, action) => {
        if (action && action.error && action.error.message.includes("500")) {
          state.isProductDisciplineInternalError = true;
        }
      })
      .addCase(productTocAsync.fulfilled, (state, action) => {
        state.productToc = action.payload;
      })
      .addCase(productModuleAsync.fulfilled, (state, action) => { 
        state.productModule = action.payload;
      })
      .addCase(productAvailableToBuyOffersAsync.fulfilled, (state, action) => {
        state.productAvailableToBuyOffers = action.payload;
        state.isAvailableToBuyOffersInternalError = false;
      })
      .addCase(productAvailableToBuyOffersAsync.rejected, (state, action) => {
        if (action && action.error && action.error.message.includes("500")) {            
          state.isAvailableToBuyOffersInternalError = true;
        }
      });
  },
});

export const productData = (state) => state.productData;
export const {
  updateProductTabs,
  updateShowDDA,
  resetProductData,
  resetProductOfferData,
  updateIsProductInternalError,
  updateIsOfferInternalError,
  updateIsAvailableToBuyOffersInternalError,
  updateIsProductDisciplineInternalError,
  updateIsActiveSeatLicenseAvaliable,
  updateActiveMobileProductTab,
} = productSlice.actions;
export default productSlice.reducer;
