import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "./userApi";
import _ from "lodash";
import { getLmsToken } from "../../util/productUtil";
import { ORG_CLASSIFICATION, USER_ROLE } from "../../../config";
import { getOrgClassification } from "../../util/userUtil";

const initialState = {
  userData: null,
  defaultInstitute: null,
  currentInstitute: null,
  isNoInstitutionProfile: false,
  isRefreshInstitution: false,
  isInstituteUpdated: false,
  isMobileView: false,
  orgClassification: ORG_CLASSIFICATION.ANONYMOUS,
  userRole: USER_ROLE.ANONYMOUS,
  isVerifiedAccount: true,
  preSelectedOrganization: null,
  isUserServiceInternalError: false,
  userConsent: false
};

export const userDataAsync = createAsyncThunk(
  "user/fetchUserData",
  async (token) => {
    const response = await fetchUserData(token);
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeCurrentInstitute: (state, action) => {
      const tempCurrentInstitute = _.find(
        state.userData.organizations,
        function (organization) {
          return organization.organization.orgId === action.payload;
        }
      );
      state.currentInstitute = tempCurrentInstitute;
      window.localStorage.setItem(
        "exchange.user.defaultInstitute",
        JSON.stringify(tempCurrentInstitute)
      );
      state.isInstituteUpdated = true;
      state.orgClassification = getOrgClassification(tempCurrentInstitute);

      if (state.orgClassification === ORG_CLASSIFICATION.HIGHER_ED) {
        state.userRole = USER_ROLE.HIGHER_ED_INSTRUCTOR;
      } else if (state.userData) {
        state.userRole = USER_ROLE.K12_BUYER;
      }
      const queryParams = new URLSearchParams(window.location.search);
      if (
        queryParams.has("organizationId") &&
        queryParams.get("organizationId").length > 0
      ) {
        queryParams.set("organizationId", action.payload);
        window.location.search = queryParams.toString();
      }
    },
    updateIsUserServiceInternalError: (state, action) => {
      state.isUserServiceInternalError = action.payload;
    },
    updateNoInstitutionProfile: (state, action) => {
      state.isNoInstitutionProfile = action.payload;
    },
    updateRefreshInstitution: (state, action) => {
      state.isRefreshInstitution = action.payload;
    },
    changeIsInstituteUpdated: (state, action) => {
      state.isInstituteUpdated = action.payload;
    },
    changeIsMobileView: (state, action) => {
      state.isMobileView = action.payload;
    },
    updateOrgClassification: (state, action) => {
      state.orgClassification = action.payload;
    },
    updateIsVerifiedAccount: (state, action) => {
      state.isVerifiedAccount = action.payload;
    },
    updatePreSelectedOrganization: (state, action) => {
      state.preSelectedOrganization = action.payload;
    },
    updateUserConsent: (state, action) => {
      state.userConsent = !state.userConsent;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userDataAsync.fulfilled, (state, action) => {
      state.userData = action.payload;
      const lmsToken = getLmsToken();

      if (lmsToken && lmsToken.length > 0 && action.payload.lmsOrg) {
        state.currentInstitute = action.payload.lmsOrg;
        state.defaultInstitute = action.payload.lmsOrg;
        window.localStorage.setItem(
          "exchange.user.lmsInstitute",
          JSON.stringify(action.payload.lmsOrg)
        );
        window.localStorage.setItem(
          "exchange.user.defaultInstitute",
          JSON.stringify(action.payload.lmsOrg)
        );

        if (
          state.currentInstitute &&
          state.currentInstitute.classification &&
          (state.currentInstitute.classification.toLowerCase() ===
            "higher ed" ||
            state.currentInstitute.classification.toLowerCase() ===
              "higher education")
        ) {
          state.orgClassification = ORG_CLASSIFICATION.HIGHER_ED;
        } else if (
          state.currentInstitute &&
          state.currentInstitute.classification &&
          state.currentInstitute.classification.toLowerCase() === "school"
        ) {
          state.orgClassification = ORG_CLASSIFICATION.SCHOOL;
        }
      } else {        
        if (
          action.payload.organizations &&
          action.payload.organizations.length > 0
        ) {
          const queryParams = new URLSearchParams(window.location.search);
          let tempDefaultOrg;

          tempDefaultOrg = _.find(
            action.payload.organizations,
            function (organization) {
              return organization.isDefaultInstitute === true;
            }
          );

          if (!tempDefaultOrg) {
            tempDefaultOrg = action.payload.organizations[0];
          }
          // EXV2-16108 - Support console course copy page redirection to PDP
          if (
            queryParams.has("organizationId") &&
            queryParams.get("organizationId").length > 0
          ) {
            const tempUrlParamOrg = _.find(
              action.payload.organizations,
              function (organization) {
                return (
                  organization.organization.orgId ===
                  queryParams.get("organizationId")
                );
              }
            );

            if (tempUrlParamOrg) {
              tempDefaultOrg = tempUrlParamOrg;
              window.localStorage.setItem(
                "exchange.user.defaultInstitute",
                JSON.stringify(tempUrlParamOrg)
              );              
            } else {
              queryParams.set(
                "organizationId",
                tempDefaultOrg.organization.orgId
              );
              window.location.search = queryParams.toString();
            }
          }

          state.defaultInstitute = tempDefaultOrg;

          if (window.localStorage.getItem("exchange.user.defaultInstitute")) {
            state.currentInstitute = JSON.parse(
              window.localStorage.getItem("exchange.user.defaultInstitute")
            );
          } else {
            state.currentInstitute = tempDefaultOrg;
            window.localStorage.setItem(
              "exchange.user.defaultInstitute",
              JSON.stringify(tempDefaultOrg)
            );
          }
        }

        if (
          state.currentInstitute &&
          state.currentInstitute.organization &&
          state.currentInstitute.organization.classification &&
          (state.currentInstitute.organization.classification.toLowerCase() ===
            "higher ed" ||
            state.currentInstitute.organization.classification.toLowerCase() ===
              "higher education")
        ) {
          state.orgClassification = ORG_CLASSIFICATION.HIGHER_ED;
        } else if (
          state.currentInstitute &&
          state.currentInstitute.organization &&
          state.currentInstitute.organization.classification &&
          state.currentInstitute.organization.classification.toLowerCase() ===
            "school"
        ) {
          state.orgClassification = ORG_CLASSIFICATION.SCHOOL;
        }
      }

      if (
        (action.payload.organizations &&
          action.payload.organizations.length > 0) ||
        (lmsToken && lmsToken.length > 0)
      ) {
        state.userData.hasInstitutions = true;
      } else {
        state.userData.hasInstitutions = false;
      }

      if (
        action.payload.permissions &&
        action.payload.permissions.indexOf("initiate_aiv") > -1
      ) {
        state.userData.selfIdentifiedInstructor = true;
      } else {
        state.userData.selfIdentifiedInstructor = false;
      }

      if (
        action.payload.permissions &&
        action.payload.permissions.length === 1 &&
        action.payload.permissions.indexOf("console_student") > -1
      ) {
        state.userData.isStudent = true;
      } else {
        state.userData.isStudent = false;
      }

      if (
        action.payload.permissions &&
        action.payload.permissions.indexOf("console_student") > -1 &&
        action.payload.permissions.indexOf("initiate_aiv") > -1
      ) {
        state.userData.isStudentInstructer = true;
      } else {
        state.userData.isStudentInstructer = false;
      }

      if (
        action.payload.permissions &&
        (action.payload.permissions.indexOf("create_revel_course") > -1 ||
          action.payload.permissions.indexOf("create_reveltest_course") > -1)
      ) {
        state.userData.isValidatedInstructor = true;
      } else {
        state.userData.isValidatedInstructor = false;
        if (state.orgClassification !== ORG_CLASSIFICATION.ANONYMOUS) {
          state.isVerifiedAccount = false;
        }
      }

      if (state.userData.isStudent) {
        state.userRole = USER_ROLE.STUDENT;
      } else {
        if (
          state.userData &&
          state.orgClassification === ORG_CLASSIFICATION.HIGHER_ED
        ) {
          state.userRole = USER_ROLE.HIGHER_ED_INSTRUCTOR;
        } else if (state.userData) {
          state.userRole = USER_ROLE.K12_BUYER;
        } else {
          state.userRole = USER_ROLE.ANONYMOUS;
        }
      }
    }).addCase(userDataAsync.rejected, (state, action) => {
        if(action.error &&
           action.error.message.includes("500")) {
           state.isUserServiceInternalError = true;
        }
    });
  },
});

export const {
  changeCurrentInstitute,
  updateIsUserServiceInternalError,
  updateNoInstitutionProfile,
  updateRefreshInstitution,
  changeIsInstituteUpdated,
  changeIsMobileView,
  updateOrgClassification,
  updateIsVerifiedAccount,
  updatePreSelectedOrganization,
  updateUserConsent,
} = userSlice.actions;

export default userSlice.reducer;
