import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPlatform: window.sessionStorage.getItem(
    "exchange.search.selectedPlatform"
  )
    ? window.sessionStorage.getItem("exchange.search.selectedPlatform")
    : null,
  selectedPlatformDisplayName: window.sessionStorage.getItem(
    "exchange.search.selectedPlatformDisplayName"
  )
    ? window.sessionStorage.getItem(
        "exchange.search.selectedPlatformDisplayName"
      )
    : null,
};

export const platformSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    updateSelectedPlatform: (state, action) => {
      state.selectedPlatform = action.payload.type;
      state.selectedPlatformDisplayName = action.payload.displayValue;
      window.sessionStorage.setItem(
        "exchange.search.selectedPlatform",
        action.payload.type
      );
      window.sessionStorage.setItem(
        "exchange.search.selectedPlatformDisplayName",
        action.payload.displayValue
      );
    },
    resetSelectedPlatform: (state, action) => {
      state.selectedPlatform = null;
      state.selectedPlatformDisplayName = null;
      window.sessionStorage.removeItem("exchange.search.selectedPlatform");
      window.sessionStorage.removeItem(
        "exchange.search.selectedPlatformDisplayName"
      );
    },
  },
  extraReducers: (builder) => {},
});

export const { updateSelectedPlatform, resetSelectedPlatform } =
  platformSlice.actions;
export default platformSlice.reducer;
